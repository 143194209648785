body {

  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #242424;
  background-image: url('../assets/images/black-abstract-background-wallpaper-image.jpg');
  
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
